@import "tailwindcss/base";

@import "shared";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

.deneme {
    right: 50%;
    bottom: 3rem;
}

.transitionArrow {
    transition: all 0.3s ease-in-out;
}

.transitionArrow2 {
    transition: all 0.3s ease-in-out;
}

.btn:hover .transitionArrow {
    transform: translateX(8px);
}

.moveArrow:hover .transitionArrow2 {
    transform: translateY(8px);
}

.showLess {
    display: none;
}

.showLess:nth-child(-n + 4) {
    display: block;
}

nav {
    .nav-items-wrapper {
        .nav-item {
            .nav-link {
                img {
                    transition: transform 0.3s ease-in-out;
                }
            }

            .dropdown {
                transition: max-height 0.3s ease-in-out;
                overflow: hidden;
            }

            &.active {
                .nav-link {
                    img {
                        transform: rotate(180deg);
                    }
                    span {
                        opacity: 1 !important;
                    }
                }

                .dropdown {
                    // just high number that the dropdown will never reach
                    max-height: 1000px;
                }
            }

            .dropdown {
                margin-top: 1.4rem;

                &:hover {
                    max-height: 1000px;
                }
            }
        }
        &:hover a.nav-link {
            &:hover {
                img {
                    transform: rotate(180deg);
                }
                + .dropdown {
                    // just high number that the dropdown will never reach
                    max-height: 1000px;
                }
            }

            &:not(:hover) span {
                color: #fff;
                opacity: 0.5;
            }
        }
    }
}

nav {
    .mobileNavItem {
        &.active {
            display: block;
        }
    }
}

// slide down mobile main nav
.overlay-slidedown {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 0.3s ease-in-out, visibility 0s 0.3s;
    transition: transform 0.3s ease-in-out, visibility 0s 0.3s;

    &.open {
        visibility: visible;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        z-index: 100;
    }
}

.overlay {
    position: absolute;
    top: 64px;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc(100vh - 64px);
}

// slide down mobile blog nav
.overlay-blog {
    position: fixed;
    width: 100vw;
}

.overlay-slidedown-blog {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 0.3s ease-in-out, visibility 0s 0.3s;
    transition: transform 0.3s ease-in-out, visibility 0s 0.3s;
}

.overlay-slidedown-blog.open {
    visibility: visible;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    z-index: 100;
}
